body {
  --color-white: #fff;
  --color-accent: #dd0e5e;
  --color-bg: #060315;
  --color-header-bg: var(--color-bg);
  --color-text: var(--color-white);
  --color-text-inverted: #000626;
  --color-card-bg: #141321;
  --box-shadow-card: none;
  --box-shadow-carousel: none;
  --color-text-secondary: var(--color-text);
  --color-title: #59e2be;
  --color-looking-for: #7a7ab4;
  --color-description: var(--color-text);
  --color-tag-bg: transparent;
  --color-tag-border: #424166;
  --color-tag-text: var(--color-text);
  --color-button-secondary-bg: #333248;
  --color-button-secondary-bg-hover: #414059;
  --color-message-highlighted: #00e1b9;
  --color-message-bg: #2e2e49;
  --color-message-inc-bg: transparent;
  --color-button-hover: #dd3a84;
}

body.light {
  --color-bg: #f9fafe;
  --color-accent: #f30e66;
  --color-header-bg: var(--color-white);
  --color-text: #000626;
  --color-text-inverted: var(--color-white);
  --color-card-bg: var(--color-white);
  --box-shadow-card: 0 5px 20px 0 rgba(0, 129, 204, 0.1);
  --box-shadow-carousel: inset 0px -100px 100px -100px rgba(0, 102, 204, 0.22);
  --color-text-secondary: #6d748d;
  --color-title: var(--color-text);
  --color-looking-for: var(--color-text-secondary);
  --color-description: var(--color-looking-for);
  --color-tag-bg: #f2f7fc;
  --color-tag-border: var(--color-tag-bg);
  --color-tag-text: #414660;
  --color-button-secondary-bg: var(--color-tag-bg);
  --color-button-secondary-bg-hover: #edf2f9;
  --color-message-highlighted: var(--color-accent);
  --color-message-bg: #7fece7;
  --color-message-inc-bg: var(--color-white);
  --color-button-hover: #e70965;
}

body.light header .logo {
  background: url('../assets/svg/logo-dark.svg');
}
